<template>
  <div id="V2Gallery" ref="v2Gallery">
    <div class="gallery_wrap">
      <div class="card_list_wrap">
        <!-- gallery list area ============ -->
        <div class="card_list ordinals">
          <!-- keyword search area ============ -->
          <div class="title_box">
            <h3>Ordinals</h3>
          </div>
          <div class="search-section">
            <div class="keyword_search_box float-left">
              <img src="/img/threespace/icon/search_icon2.png" />
              <input
                type="text"
                placeholder="Search"
                autocomplete="off"
                v-model="externalParams.keywordText"
                @keypress.enter.prevent="onClickSearch"
              />
              <button
                @click="onClickResetFilterKeyword"
                v-if="externalParams.keywordText !== ''"
                class="search_reset_btn px-0"
              >
                <img src="/img/threespace/icon/drop-x.png" />
              </button>
            </div>
            <button @click="onClickSearch" class="search_btn">Search</button>
            <div class="sort-section">
              <b-button
                @click.stop="showSortByFilter"
                class="sort_btn float-right"
                :class="{ active: visibleSortBy }"
              >
                {{
                  Object.keys(sortSelected).length > 0
                    ? sortSelected.text
                    : $t("product.sortBy")
                }}
                <img src="/img/threespace/icon/chevron2.png" />
                <!-- <v-icon class="float-right" v-if="visibleSortBy"
                  >mdi-chevron-up</v-icon
                > -->
                <!-- <v-icon class="float-right" v-else>mdi-chevron-down</v-icon> -->
              </b-button>
              <div
                class="sort_select_list"
                :class="{ open: visibleSortBy }"
                v-click-outside:showSortByFilter="false"
              >
                <template v-for="(item, i) in sortOptions">
                  <div
                    class="sort-item"
                    :key="i"
                    @click="onClickSortByItem(item)"
                    :class="{ active: sortSelected.text === item.text }"
                  >
                    {{ item.text }}
                  </div>
                </template>
              </div>
            </div>
          </div>
          <section class="gallery">
            <div class="total_count">
              <div class="light">{{ $t("product.filterTotal") }}</div>
              {{ galleryProductItems.totalElements }}
              {{ $t("product.countUnit") }}
            </div>
            <transition-group name="gallery" tag="div" class="drops-wrap">
              <template
                v-if="
                  producthItems === undefined ||
                  producthItems === null ||
                  producthItems.length === 0
                "
              >
                <div class="col-12 mb-5 py-5 text-center" key="0">
                  <p class="font-lg">{{ $t("product.noResult") }}</p>
                </div>
              </template>
              <div
                class="position-relative"
                v-for="(item, i) in producthItems"
                :key="i"
                :ref="'cartItem' + item.idx"
              >
                <div
                  class="card-list card_list_item cursor-pointer"
                  :id="'galleryItem' + item.idx"
                  @click="
                    showProductDetailOrOpenSea(
                      item.idx,
                      item.filterDisplayOnly,
                      item
                    )
                  "
                >
                  <v-lazy
                    :options="{ threshold: 0.5 }"
                    transition="fade-transition"
                  >
                    <div
                      class="card_media_box"
                      @contextmenu.prevent.stop
                      :class="{ skeleton_display_none: !isBusy }"
                    >
                      <!-- <CardHoverBox
                        :favoritedCnt="item.favoritedCnt"
                        :pageViewCnt="item.pageViewCnt"
                        :isExternalCollection="false"
                      /> -->
                      <template
                        v-if="isProductFileTypeImage(item.productFileType)"
                      >
                        <b-img :src="replaceDisplayCardUrl(item)"></b-img>
                      </template>
                      <template v-else>
                        <video
                          :poster="replaceDisplayPosterUrl(item)"
                          :src="replaceDisplayCardUrl(item)"
                          type="video/webm"
                          muted
                          loop
                          autoplay
                          playsinline
                        ></video>
                      </template>
                      <b-skeleton-img
                        no-aspect
                        width="100%"
                        height="100%"
                      ></b-skeleton-img>
                    </div>
                  </v-lazy>
                  <div class="card-info">
                    <div class="card_title_box" v-if="!isBusy">
                      <!-- <div
                        class="artist_name"
                        @click.stop="onClickArtist(item.idxMember)"
                      > -->
                      <section class="card-info__header">
                        <!-- <template v-if="item.profileImage === undefined">
                          <CImg
                            class="c-avatar-img mr-2"
                            height="25"
                            src="/img/avatars/profile-gray-person.jpg"
                          />
                        </template>
                        <template v-else>
                          <CImg
                            class="c-avatar-img mr-2"
                            height="25"
                            :src="item.profileImageUrl"
                          />
                        </template> -->
                        <p @click.stop="onClickArtist(item.idxMember)">
                          <template
                            v-if="
                              activeLanguage !== undefined &&
                              activeLanguage === 'ko'
                            "
                          >
                            {{ item.artistName || item.artistNameEn }}
                          </template>
                          <template v-else>
                            {{ item.artistNameEn || item.artistName }}
                          </template>
                        </p>
                        <FavoritedButton
                          :favoritedItem="item"
                          @onClickFavoritedButton="onClickFavoritedButton"
                        />
                      </section>
                      <p class="card-info__title">
                        <template
                          v-if="
                            activeLanguage !== undefined &&
                            activeLanguage === 'ko'
                          "
                        >
                          {{ item.productName || item.productNameEn }}
                        </template>
                        <template v-else>
                          {{ item.productNameEn || item.productName }}
                        </template>
                      </p>
                      <!-- </div> -->
                      <!-- <h3>
                        <template
                          v-if="
                            activeLanguage !== undefined &&
                            activeLanguage === 'ko'
                          "
                        >
                          {{ item.productName || item.productNameEn }}
                        </template>
                        <template v-else>
                          {{ item.productNameEn || item.productName }}
                        </template>
                      </h3> -->
                      <!-- <CImg
                        src="/img/threespace/icon/ethereum-black.png"
                        v-if="item.nftType === 'ETHEREUM'"
                        class="chain-logo"
                      />
                      <CImg
                        src="/img/threespace/icon/matic-black.png"
                        v-if="item.nftType === 'POLYGON'"
                        class="chain-logo"
                      />
                      <CImg
                        src="/img/threespace/icon/kaia-black.png"
                        v-else-if="
                          item.nftType === 'KLAYTN' || item.nftType === 'KLIP'
                        "
                        class="chain-logo"
                      />
                      <CImg
                        src="/img/threespace/icon/bitcoin-black.png"
                        v-else-if="item.nftType === 'ORDINALS'"
                        class="chain-logo"
                      /> -->
                    </div>
                    <div class="card_title_box" v-else>
                      <p class="artist_name">
                        <b-skeleton
                          type="avatar"
                          height="30px"
                          width="30px"
                          class="float-left"
                        ></b-skeleton>
                        <b-skeleton
                          class="float-left mt-2 ml-2"
                          width="100px"
                        ></b-skeleton>
                      </p>
                    </div>
                    <!-- <div class="card-info__price" v-if="!isBusy">
                      <div
                        class="text_wrap"
                        :class="{
                          soldout:
                            item.status === 'SOLDOUT' ||
                            item.status === 'PRIVATESOLD',
                        }"
                      >
                        <template
                          v-if="
                            item.status === 'SOLDOUT' ||
                            item.status === 'PRIVATESOLD'
                          "
                        >
                          {{
                            item.buyerNickname !== undefined &&
                            item.buyerNickname !== ""
                              ? "Owned by @" + item.buyerNickname
                              : $t("product.statusSoldOut")
                          }}
                        </template>
                        <template v-else-if="item.status === 'EXHIBIT'">
                          {{ $t("product.statusExhibit") }}
                        </template>
                        <template
                          v-else-if="
                            item.nftType === 'ORDINALS' &&
                            activeLanguage !== undefined &&
                            activeLanguage === 'en'
                          "
                        >
                          {{ getSatoshiToBtc(item.productCoinPrice) }}
                          {{ getCoinCurrencyByNftType(item.nftType) }}
                        </template>
                        <template v-else-if="item.nftType === 'ORDINALS'">
                          {{ getSatoshiToKRW(item.productCoinPrice) }} KRW
                        </template>
                        <template
                          v-else-if="
                            activeLanguage !== undefined &&
                            activeLanguage === 'en' &&
                            isCoinPriceNonZero(item.nftType) &&
                            isAcceptableNftType(item.nftType)
                          "
                        >
                          {{
                            getProductCoinPrice(item.productPrice, item.nftType)
                          }}
                          {{ getCoinCurrencyByNftType(item.nftType) }}
                        </template>
                        <template v-else>
                          {{ item.productPrice | currency }}
                          {{
                            item.productPrice !== undefined &&
                            item.productPrice > 0
                              ? "KRW"
                              : $t("product.statusExhibit")
                          }}
                        </template>
                      </div>
                    </div>
                    <div class="card_price_box" v-else>
                      <span>
                        <b-skeleton width="50%" height="25px"></b-skeleton>
                      </span>
                    </div> -->
                  </div>
                </div>
              </div>
            </transition-group>

            <div
              v-if="!isLast"
              class="loading-wrapper"
              ref="infiniteScrollTrigger"
              style="text-align: center; padding: 40px 0px 0px 0px"
            >
              <div class="loading-spinner">
                <b-spinner variant="primary" label="Loading..."></b-spinner>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-12 text-right">
                <b-pagination
                  v-on:change="onPaging"
                  :total-rows="totalRows"
                  v-model="currentPage"
                  :per-page="perPage"
                  hide-ellipsis
                  limit="10"
                  aria-controls="role-function-list"
                  class="float-right gallery_pagination"
                >
                  <template #first-text>
                    <img
                      class="page_icon rotate_page_icon"
                      src="/img/threespace/icon/pagination_arrow2_icon.png"
                    />
                  </template>
                  <template #prev-text>
                    <img
                      class="page_icon rotate_page_icon"
                      src="/img/threespace/icon/pagination_arrow_icon.png"
                    />
                  </template>
                  <template #next-text>
                    <img
                      class="page_icon"
                      src="/img/threespace/icon/pagination_arrow_icon.png"
                    />
                  </template>
                  <template #last-text>
                    <img
                      class="page_icon"
                      src="/img/threespace/icon/pagination_arrow2_icon.png"
                    />
                  </template>
                  <template #page="{ page }">
                    <span :class="{ active_num: page === currentPage }">
                      {{ page }}
                    </span>
                  </template>
                </b-pagination>
              </div>
            </div> -->
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createNamespacedHelpers,
  mapActions,
  mapState,
  mapGetters,
} from "vuex";
import FavoritedButton from "./components/common/FavoritedButton.vue";
import CardHoverBox from "./components/common/CardHoverBox.vue";
import coinUtils from "../../mixins/CoinUtils";
const codeHelper = createNamespacedHelpers("code");
const galleryHelper = createNamespacedHelpers("gallery");
const coinHelper = createNamespacedHelpers("coin");

export default {
  components: {
    FavoritedButton,
    CardHoverBox,
  },
  name: "Drops",
  metaInfo() {
    return {
      title: this.$t("meta.drops.title") + " | 3space Art",
      meta: [
        {
          vmid: "title",
          name: "title",
          content: this.$t("meta.drops.title") + " | 3space Art",
        },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.drops.description"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.drops.title") + " | 3space Art",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.drops.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.origin + this.$route.path,
        },
      ],
    };
  },
  data: function () {
    return {
      visibleFilterPopup: false,
      visibleSortBy: false,
      visibleBlockchain: true,
      visibleArttype: true,
      visibleDisplay: true,
      visiblePaytype: true,
      isBusy: false,
      perPage: 30,
      isLast: false,
      totalRows: 0,
      currentPage: 1,
      sort: [
        { id: "displayDatetime", desc: "desc" },
        { id: "idx", desc: "desc" },
      ],
      sortSelected: {},
      sortOptions: [
        {
          value: { id: "displayDatetime", desc: "desc" },
          text: this.$t("product.sortByNewest"),
        },
        {
          value: { id: "orderPayment.orderDate", desc: "desc" },
          text: this.$t("product.sortByRecentlySold"),
        },
        {
          value: { id: "productPrice", desc: "desc" },
          text: this.$t("product.sortByHighestPrice"),
        },
        {
          value: { id: "productPrice", asc: "asc" },
          text: this.$t("product.sortByLowestPrice"),
        },
      ],
      externalParams: {
        keywordCluster: [],
        keywordChain: [],
        keywordFiletype: [],
        keywordStatus: [],
        keywordPaytype: [],
        keywordText: "",
        keywordOnlyOrdinals: true,
      },
      statusOptions: [
        { value: "FORSALE", text: this.$t("product.statusForSaleCard") },
        { value: "SOLDOUT", text: this.$t("product.statusSoldOut") },
        { value: "EXHIBIT", text: this.$t("product.statusExhibit") },
      ],
      paytypeOptions: [
        { value: "TRANSFER", text: this.$t("product.paytypeKRW") },
        { value: "ETH", text: this.$t("product.paytypeETH") },
        { value: "KLAY", text: this.$t("product.paytypeKLAY") },
        { value: "MATIC", text: this.$t("product.paytypeMATIC") },
        { value: "SSX", text: this.$t("product.paytypeSSX") },
        { value: "BTC", text: this.$t("product.paytypeBTC") },
      ],
      isShowFilter: false,
      observer: null,
      isLoading: false,
      producthItems: [],
    };
  },
  mixins: [coinUtils],
  computed: {
    ...codeHelper.mapState(["commonCodes"]),
    ...galleryHelper.mapState([
      "galleryProductItems",
      "galleryProductItemsContent",
      "previousParams",
    ]),
    ...mapState("coin", ["eth", "klay", "matic", "btc"]),
    ...mapState({
      me: (state) => {
        return state.auth.me || {};
      },
    }),
    ...mapGetters("auth", ["isAuthenticated"]),
    isMobile: {
      get: function () {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      },
      set: function () {
        // isMobile 상태에 따라서 변경
      },
    },
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getTickers();
      this.resetGalleryProducts();
      this.requestChildCodes(["ART_FILTER_BLOCKCHAIN", "ART_FILTER_TYPE"]);
      this.requestGalleryProductItems();
      this.setupInfiniteScroll();
    });
  },
  updated() {
    this.setupInfiniteScroll();
  },
  beforeMount() {
    // previousParams Set
    if (this.$route.params.linkType === undefined) {
      if (Object.keys(this.previousParams).length > 0) {
        this.externalParams = this.previousParams.externalParams;
        this.currentPage = this.previousParams.currentPage;
        this.sort = this.previousParams.sort;
        this.sortSelected = this.previousParams.sortSelected;
        this.totalRows = this.previousParams.totalRows;
      }
    } else {
      this.setGalleryPreviousParams({});
    }
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vnode) {
        // create event
        el.eventClickOutside = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.arg](binding.value);
          }
        };
        // event binding
        document.body.addEventListener("click", el.eventClickOutside);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.eventClickOutside);
      },
    },
  },
  methods: {
    ...codeHelper.mapActions(["getChildMultiCodes"]),
    ...galleryHelper.mapActions([
      "getGalleryProductsDrops",
      "setGalleryPreviousParams",
    ]),
    ...galleryHelper.mapMutations(["resetGalleryProducts"]),
    ...coinHelper.mapActions(["getTickers"]),
    ...mapActions("favorited", [
      "addFavorited",
      "deleteFavorited",
      "toggleFavorited",
    ]),
    ...mapActions("auth", ["verifyTokenCallback"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    setAltImg(event, item) {
      if (!item.error) {
        event.target.src = this.replaceDisplayCardUrl(item);
      }
      item.error = true;
    },
    requestChildCodes(codes) {
      this.getChildMultiCodes(codes).then();
    },
    requestGalleryProductItems() {
      this.isBusy = true;
      this.getGalleryProductsDrops(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
        .then((data) => {
          this.producthItems.push(...data.content);
          this.isLast = data.last;
          this.totalRows = this.galleryProductItems.totalElements;
          if (Object.keys(this.previousParams).length > 0) {
            // 클릭한 elements로 이동
            const el = document.getElementById(this.previousParams.clickElId);
            if (el) {
              var headerOffset = 120;
              var elementPosition = el.getBoundingClientRect().top;
              var offsetPosition =
                elementPosition + window.pageYOffset - headerOffset;
              window.scrollTo({
                top: offsetPosition,
                behavior: "auto",
              });
            }
          }
          setTimeout(() => {
            this.isBusy = false;
            this.setGalleryPreviousParams({});
          }, 2000);
        })
        .catch((error) => {
          this.isBusy = false;
          this.setGalleryPreviousParams({});
          this.$log.error(error);
        });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams,
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    onClickViewMore() {
      if (this.isLast !== true) {
        this.requestGalleryProductItems();
        this.getTickers();
      }
    },
    onClickSearch() {
      this.isLast = true;
      this.currentPage = 1;
      this.producthItems = [];
      this.requestGalleryProductItems();
    },
    showProductDetail(idx) {
      this.$router.push("/art/" + idx);
    },
    showProductDetailOrOpenSea(idx, filterDisplayOnly, item) {
      if (!filterDisplayOnly) {
        let previousParams = {
          externalParams: this.externalParams,
          currentPage: this.currentPage,
          sort: this.sort,
          sortSelected: this.sortSelected,
          clickElId: "galleryItem" + idx,
          totalRows: this.galleryProductItems.totalElements,
        };
        this.setGalleryPreviousParams(previousParams);
        this.$router.push("/art/" + idx);
      } else {
        if (
          item.nftType === "KLIP" &&
          item.klipNftTokenId !== undefined &&
          item.klipNftTokenId !== null &&
          item.klipNftTokenId !== ""
        ) {
          // TODO 컨트랙주소 고정
          const url =
            "https://opensea.io/assets/klaytn/0x2abec25064cbcd8d3c298903098840f95a432073/" +
            item.klipNftTokenId;
          window.open(url, "_blank");
        }
      }
    },
    onClickArtist(idxMember) {
      let idxEncode = window.btoa(idxMember);
      this.$router.push("/collections/" + idxEncode);
    },
    isProductFileTypeImage(_productFileType) {
      if (
        _productFileType !== undefined &&
        _productFileType !== null &&
        _productFileType.startsWith("image")
      ) {
        return true;
      } else {
        return false;
      }
    },
    replaceThumbnailUrl(item) {
      const w = "400";
      const h = "463";
      const thumbnailUrl = `https://collection.3space.art/${process.env.NODE_ENV}/${item.idx}.jpg`;
      if (
        item.displayPoster !== undefined &&
        item.displayPoster !== null &&
        item.displayPoster !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return `${thumbnailUrl}?src=${item.displayPosterUrlThreespace}&w=${w}&h=${h}`; // 3space S3 URL
      } else {
        if (
          item.displayPosterUrl !== undefined &&
          item.displayPosterUrl.startsWith("https://ipfs.io/")
        ) {
          item.displayPosterUrl = item.displayPosterUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return `${thumbnailUrl}?src=${item.displayPosterUrl}&w=${w}&h=${h}`;
      }
    },
    replaceDisplayPosterUrl(item) {
      if (
        item.displayPoster !== undefined &&
        item.displayPoster !== null &&
        item.displayPoster !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return item.displayPosterUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayPosterUrl !== undefined &&
          item.displayPosterUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayPosterUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return item.displayPosterUrl;
      }
    },
    replaceDisplayCardUrl(item) {
      if (
        item.displayCardFile !== undefined &&
        item.displayCardFile !== null &&
        item.displayCardFile !== "" &&
        item.displayCardFile === "THREESPACE"
      ) {
        return item.displayCardUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayCardUrl !== undefined &&
          item.displayCardUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayCardUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return item.displayCardUrl;
      }
    },
    showFilterModal(value) {
      if (typeof value === "boolean") this.isShowFilter = value;
      else {
        this.isShowFilter = !this.isShowFilter;
      }
    },
    showSortByFilter(value) {
      if (typeof value === "boolean") this.visibleSortBy = value;
      else {
        this.visibleSortBy = !this.visibleSortBy;
      }
    },
    onClickResetFilter() {
      this.externalParams.keywordChain = [];
      this.externalParams.keywordFiletype = [];
      this.externalParams.keywordStatus = [];
      this.externalParams.keywordPaytype = [];
      if (this.isMobile) this.visibleFilterPopup = false;
      this.onClickSearch();
    },
    onClickResetFilterKeyword() {
      this.externalParams.keywordText = "";
      this.onClickSearch();
    },
    onClickSortByItem(item) {
      this.sort[0] = item.value;
      this.visibleSortBy = false;
      this.currentPage = 1;
      this.sortSelected = item;
      this.requestGalleryProductItems();
    },
    onPaging(page) {
      window.scrollTo(0, 0);
      this.currentPage = page;
      this.requestGalleryProductItems();
    },
    onClickFavoritedButton(favoritedItem) {
      if (this.isAuthenticated) {
        this.verifyTokenCallback().then(() => {
          if (
            this.me === undefined ||
            this.me === null ||
            this.me.idx === undefined ||
            this.me.idx === null ||
            this.me.idx === ""
          ) {
            // 로그인
            this.hasKlaytnAddr = false;
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("validation.requiredLogin"),
              show: true,
              fontSize: "sm",
            });
          } else {
            // 로그인 되어있음
            if (favoritedItem.favorited) {
              // true이면 좋아요 헤재
              this.toggleFavorited(favoritedItem);
              this.deleteFavorited({ idxProduct: favoritedItem.idx });
            } else {
              // false이면 좋아요
              this.toggleFavorited(favoritedItem);
              this.addFavorited({ idxProduct: favoritedItem.idx });
            }
          }
        });
      } else {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("validation.requiredLogin"),
          show: true,
          fontSize: "sm",
        });
      }
    },
    setupInfiniteScroll() {
      this.observer = new IntersectionObserver(
        (entries) => {
          const target = entries[0];
          if (target.isIntersecting && !this.isLoading && !this.isLast) {
            this.loadMoreItems();
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.1,
        }
      );
      if (this.$refs.infiniteScrollTrigger) {
        this.observer.observe(this.$refs.infiniteScrollTrigger);
      }
    },

    loadMoreItems() {
      if (this.isLoading || this.isLast) return;

      this.isLoading = true;
      this.currentPage += 1;

      this.getGalleryProductsDrops(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
        .then((data) => {
          this.producthItems.push(...data.content);
          this.isLast = data.last;
          this.totalRows = this.galleryProductItems.totalElements;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$log.error(error);
        });
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return "";
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>

<style scoped>
.v-icon.v-icon::after {
  background-color: rgba(0, 0, 0, 0) !important;
}

.gallery-item {
  display: inline-block;
  margin-right: 10px;
}
.gallery-enter-active,
.gallery-leave-active {
  transition: all 1s;
}
.gallery-enter,
.gallery-leave-to {
  opacity: 0;
}
</style>
